/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-06 15:49:31
 * @Description: 审核管理模块路由
 */
import Layout from "@views/layout"; // 布局

const auditRouter = {
  path: "/audit",
  component: Layout,
  name: "AUDIT",
  meta: { title: "审核管理", is_menu: true },
  redirect: { name: "AUDIT_COURSEWARE" },
  children: [
    {
      path: "courseware",
      name: "AUDIT_COURSEWARE",
      meta: { title: "资源审核", is_menu: true },
      component: () => import("@views/audit/coursewareAudit"),
    },
    {
      path: "coursewareSharing",
      name: "AUDIT_COURSEWARE_SHARING",
      meta: { title: "资源共享", is_menu: true },
      component: () => import("@views/audit/coursewareSharing"),
    },
    {
      path: "courseware/detail/:audit/:id",
      name: "AUDIT_COURSEWARE_DETAIL",
      meta: {
        title: "资源审核>资源详情",
        is_menu: false,
        prentRoute: "AUDIT_COURSEWARE",
      },
      component: () => import("@views/coursewareDetail"),
      beforeEnter: (to, from, next) => {
        to.meta.title = `${
          (to.params.audit == 1 && "资源审核") || "资源共享"
        }>资源详情`;
        to.meta.prentRoute =
          (to.params.audit == 1 && "AUDIT_COURSEWARE") ||
          "AUDIT_COURSEWARE_SHARING";
        return next();
      },
    },
    {
      path: "examination",
      name: "AUDIT_EXAMINATION",
      meta: { title: "试卷审核", is_menu: true },
      component: () => import("@views/audit/examinationAudit"),
    },
    {
      path: "examinationSharing",
      name: "AUDIT_EXAMINATION_SHARING",
      meta: { title: "试卷共享", is_menu: true },
      component: () => import("@views/audit/examinationSharing"),
    },
    {
      path: "examination/detail/:audit/:id",
      name: "AUDIT_EXAMINATION_DETAIL",
      meta: {
        title: "试卷共享>试卷详情",
        is_menu: false,
        prentRoute: "AUDIT_EXAMINATION_SHARING",
      },
      component: () => import("@views/examinationDetail"),
      beforeEnter: (to, from, next) => {
        to.meta.title = `${
          (to.params.audit == 1 && "试卷审核") || "试卷共享"
        }>试卷详情`;
        to.meta.prentRoute =
          (to.params.audit == 1 && "AUDIT_EXAMINATION") ||
          "AUDIT_EXAMINATION_SHARING";
        return next();
      },
    },
    {
      path: "teacher",
      name: "AUDIT_TEACHER",
      meta: { title: "教师审核", is_menu: true },
      component: () => import("@views/audit/teacherAudit"),
    },
  ],
};

export default auditRouter;
