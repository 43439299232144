/*
 * @Descripttion: 公共信息存储
 * @Author: Dyf
 * @Date: 2023-05-11
 * @LastEditors: Dyf
 */
import * as common from "@api/common";

const state = {
  gradeList: [], // 年级
  gradeClass: [], // 年级列表（树）
  subjectList: [], // 科目
  editionTree: [], // 教材树形数据
  province: [], // 省市区数据
  questionType: [], // 题型数据
  menuRoot: [], // 用户菜单权限
};

const mutations = {
  setStateNewValue: (state, obj) => {
    state[obj.key] = obj.value;
  },
};

const actions = {
  /** 初始化公共数据 */
  initCommon({ dispatch }) {
    dispatch("getMenuRoot");
    dispatch("getGrade");
    dispatch("getGradeClass");
    dispatch("getSubject");
    dispatch("getEditionTree");
    dispatch("getProvince");
    dispatch("getQuestionType");
  },
  
  /** 年级列表 */
  async getGrade({ commit }) {
    let { data } = await common.$getGrade();
    await commit("setStateNewValue", {
      key: "gradeList",
      value: data,
    });
  },

  /** 年级班级（树） */
  async getGradeClass({ commit }) {
    let { data } = await common.$getGradeClass({
      type: 2,
      scsch_id:sessionStorage.getItem("schoolId")
    }
    );
    await commit("setStateNewValue", {
      key: "gradeClass",
      value: data,
    });
  },
  /** 科目列表 */
  async getSubject({ commit }) {
    let { data } = await common.$getSubject();
    await commit("setStateNewValue", {
      key: "subjectList",
      value: data,
    });
  },
  /**
   * 科目教材数据
   * @param {number} grade 年级编码
   */
  async getEditionTree({ commit }, grade = 1) {
    let { data } = await common.$getEditionTree(
      grade,
      sessionStorage.getItem("schoolId")
    );
    data.forEach((item) => {
      if (item.child_node.length == 0) {
        delete item.child_node;
      }
      if (item.child_node) {
        item.child_node.map(
          (citem) =>
            citem.child_node &&
            citem.child_node.length == 0 &&
            delete citem.child_node
        );
      }
    });
    await commit("setStateNewValue", {
      key: "editionTree",
      value: data,
    });
  },
  /** 省市区数据 */
  async getProvince({ commit }) {
    let { data } = await common.getProvinceCity();
    data.forEach((item) => {
      if(item.children){
        item.children = item.children.filter((citem) => citem.ddi_id != -1);
        item.children.map(
          (citem) => citem.children.length == 0 && delete citem.children
        );
        item.children.forEach((citem) => {
          if(citem.children){
            citem.children = citem.children.filter((aitem) => aitem.ddi_id != -1);
            citem.children.map(
              (aitem) => aitem.children.length == 0 && delete aitem.children
            );
          }
        });
      }
    });
    await commit("setStateNewValue", {
      key: "province",
      value: data,
    });
  },

  /** 题型数据 */
  async getQuestionType({ commit }) {
    let { data } = await common.$getQuestionType();
    await commit("setStateNewValue", {
      key: "questionType",
      value: data,
    });
  },
  // 获取用户菜单访问权限
  async getMenuRoot({ commit }) {
    let { data } = await common.$menuRoot();
    await commit("setStateNewValue", {
      key: "menuRoot",
      value: data,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
