/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-28 20:41:27
 * @Description: 首页模块路由
 */
import Layout from "@views/layout"; // 布局

const homeRouter = {
  path: "/",
  component: Layout,
  name: "PLABOARD",
  meta: { title: "手写板管理", is_menu: true },
  redirect: { name: "plaboard" },
  children: [
    {
      path: "plaboard",
      name: "PLABOARD",
      meta: { title: "首页", is_menu: false, prentRoute: "PLABOARD" },
      component: () => import("@views/plaboard/plaboard"),
    },
  ],
};

export default homeRouter;
