<template>
  <div>
    <div v-if="type =='number'">
      <el-input-number v-model="inputValue" :min="1" />
    </div>
    <div v-else-if="type =='DatePicker'">
      <el-date-picker v-model="inputValue" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" v-if="isrange == true">
      </el-date-picker>
      <el-date-picker v-model="inputValue" type="date" :placeholder="placeholder" align="right" v-if="isrange == false">
      </el-date-picker>
    </div>
    <div v-else-if="type =='DateTimePicker'">
      <el-date-picker v-model="inputValue" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" align="right" v-if="isrange == true">
      </el-date-picker>
      <el-date-picker v-model="inputValue" type="datetime" :placeholder="placeholder" align="right" v-if="isrange == false">
      </el-date-picker>
    </div>
    <div v-else-if="type =='TimePicker'">
      <el-time-picker is-range v-model="inputValue" :picker-options="pickerOptions" :placeholder="placeholder" v-if="isrange == true">
      </el-time-picker>
      <el-time-picker v-model="inputValue" :picker-options="pickerOptions" :placeholder="placeholder" v-if="isrange == false">
      </el-time-picker>
    </div>
    <div v-else>
      <div v-if="clearable">
        <el-input  v-model.trim="inputValue" :placeholder="placeholder" :rows="rows" :maxlength="maxlength" :type="type"  show-word-limit clearable />
      </div>
      <div v-else>
        <el-input  v-model.trim="inputValue" :placeholder="placeholder" :rows="rows" :maxlength="maxlength" :type="type"  show-word-limit />
      </div>
    </div>

    <div v-if="isTips">
      <p style="float:left;padding-left:20px;color: red;">提示：{{ valTips }}</p>
    </div>
  </div>

</template>

<script>
import { Empty } from 'element-ui'
export default {
  name: "TryInput",
  data() {
    return {
      inputValue: this.value,
      isTips: false,//是否显示提示
    };
  },
  props: {
    // 绑定的值
    value: {
      type: [String,Number,Array,Date],
      default: ""
    },
    pickerOptions:{
      type: Object,
      default:function () {
        return  {
          start: '00:00',
          step: '00:15',
          end: '24:00'
        }
      }
    },
    clearable:{
      type: Boolean,
      default: false
    },
    isrange:{
      type: Boolean,
      default: false
    },
    rows:{
      type: Number,
      default: 1
    },
    maxlength:{
      type: String,
      default: '50'
    },
    type: { // 类型
      type: String,
      default: 'text'
    },
    placeholder: { //  占位符
      type: String,
      default: '请选择'
    },
    require: {//是否验证必填
      type: Boolean,
      default: false
    },
    valTips: {//验证提示
      type: String,
      default: '必选项！'
    },
  },
  watch: {
    value(n,o) {
      this.inputValue = n
      if (this.require) {
        if ((n == "" || n.length == 0)) {
          this.isTips = true;
        } else {
          this.isTips = false;
        }
      }
    },
    inputValue: function (newValue) {
      // 修改调用者传入的值
      this.$emit('input', newValue)
    },
  }
};
</script>

<style>

</style>
