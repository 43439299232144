/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: wang hai yang
 * @LastEditTime: 2023-05-16 14:00:20
 * @Descripttion: 公共模块接口
 */
import HTTP from "@utils/http";
import { PAGE_SIZE } from "@config";

const PROTAL = "/protal/common/";

/**
 * 手机验证码
 * @param {object} params
 * @returns
 */
export async function getPhoneCode(params) {
  try {
    return await HTTP.post(PROTAL + "get_smscaptcha", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取省市区树状
 * @param {object} params
 * @returns
 */
export async function getProvinceCity(params) {
  try {
    return await HTTP.post(PROTAL + "get_province_city_area", params);
  } catch (error) {
    return error;
  }
}
/**
 * 获取省市区下面的学校
 * @param {object} params
 * @returns
 */
export async function getSchool(params) {
  try {
    return await HTTP.post(PROTAL + "get_school", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取年级数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getGrade() {
  try {
    return await HTTP.post("/protal/common/grade_data");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学校下的班级（树）
 * @Author: Dyf
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $getGradeClass(params) {
  try {
    return await HTTP.post("/protal/common/get_school_class", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目教材数据（树）
 * @Author: Dyf
 * @param {number} grade_id 年级编码
 * @param {number} scsch_id 学校id
 * @return {*}
 */
export async function $getEditionTree(grade_id, scsch_id) {
  try {
    return await HTTP.post("/protal/common/edition_data", {
      grade_id,
      scsch_id,
    });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 上传图片
 * @Author: Dyf
 * @param {file} file 图片文件
 * @return {*}
 */
export async function $uploadImg(file) {
  const formData = new FormData();
  formData.append("file", file, file.name);
  try {
    return await HTTP.post("/protal/tool/upLoadimage", formData);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取题型数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getQuestionType() {
  try {
    return await HTTP.get("/protal/tool/question_type");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目数据
 * @Author: Dyf
 * @return {*}
 */
export async function $getSubject() {
  try {
    return await HTTP.get("/protal/tool/subject");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取科目相关教材版本
 * @Author: Dyf
 * @param {number} sysub_id 科目id
 * @return {*}
 */
export async function $getSubTextbook(sysub_id) {
  try {
    return await HTTP.post("/protal/tool/edition", { sysub_id });
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion:获取操作手册 
 * @Author: Dyf
 * @param {number} dgi_code 获取端口：TEACHER=教师端，EDUCATION=教育局端，SCHOOL=学校端，STUDENT=学生端，PARENT=家长端
 * @return {*}
 */
export async function $get_manual(dgi_code) {
  try {
    return await HTTP.post("/protal/tool/get_manual", { dgi_code });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 筛选章节信息
 * @Author: Dyf
 * @param {number} params.grade 年级编码
 * @param {number} params.sysub_id 科目id
 * @param {number} params.sysub_idsyedi_id 教材版本id
 * @param {number} params.sysub_idsyedi_version 教材版号
 * @param {number} params.sccou_cha_pid 章id (查询节)
 * @param {string} params.chapter_word 章节关键字
 * @return {*}
 */
export async function $getChapters(params) {
  try {
    return await HTTP.post("/protal/courseschapter/get_chapter", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取学校所有老师
 * @Author: Dyf
 * @param {number} params.teuse_ide_grade 年级id
 * @param {number} params.sccla_id 班级id
 * @param {number} params.sysub_id 科目id
 * @return {*}
 */
export async function $schoolTeachers(params) {
  try {
    return await HTTP.post("/school/teauser/get_school_teacher", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 筛选科目章节信息
 * @Author: Dyf
 * @param {number} params.grade 年级编码
 * @param {number} params.sysub_id 科目id
 * @param {number} params.sccou_cha_pid 章id (查询节)
 * @param {string} params.chapter_word 章节关键字
 * @return {*}
 */
export async function $getSubjectChapters(params) {
  try {
    return await HTTP.post("/school/schcourses/get_school_chapter", params);
  } catch (error) {
    return error;
  }
}
/*******
 * @Descripttion: 学校教材信息
 * @Author: 
 * @param {number}  grade 年级  sysub_id 科目  semester 学期  sccou_cha_pid 查询章节的时候传递  chapter_word 关键字 is_trees 是否返回树状1是 2否
 * @return {*}
 */
export async function $get_school_course_list(params) {
  try {
    return await HTTP.get("/school/schcourses/get_school_course_list", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 文件下载
 * @Author: Dyf
 * @param {string} filePath 资源文件地址
 * @return {*}
 */
export async function $downloadFile(filePath) {
  try {
    return await HTTP.post("/protal/common/download_file", { filePath });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 操作日志
 * @Author: Dyf
 * @param {number} pageindex 页码
 * @param {number} pagesize 每页条数
 * @return {*}
 */
export async function $operationLog(pageindex, pagesize = PAGE_SIZE) {
  try {
    return await HTTP.get("/school/schlog/list", { pageindex, pagesize });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 导出操作日志
 * @Author: Dyf
 * @return {*}
 */
export async function $exportLog() {
  try {
    return await HTTP.get("/school/schlog/export");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取验证图片组
 * @Author: Dyf
 * @return {*}
 */
export async function $verifyImgs() {
  try {
    return await HTTP.post("protal/common/get_captcha_images");
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 获取用户菜单权限
 * @Author: Dyf
 * @return {*}
 */
export async function $menuRoot() {
  try {
    return await HTTP.post("/school/menu/getusermenu");
  } catch (error) {
    return error;
  }
}
