import { getUserInfo } from "@api/schoolManager";
import { $noReadMsg } from "@api/home";
const state = {
  userInfo: {},
  hasNoreadMsg: false,
};

const mutations = {
  setStateNewValue: (state, obj) => {
    state[obj.key] = obj.value;
  },
};

const actions = {
  // 获取用户个人信息
  async gainUserInfo({ commit }) {
    const res = await getUserInfo();
    sessionStorage.setItem("schoolId", res.data.scsch_id);
    await commit("setStateNewValue", {
      key: "userInfo",
      value: res.data,
    });
  },
  /** 获取用户是否有未读消息 */
  async getMsgReadStatus({ commit }) {
    const { data } = await $noReadMsg();
    await commit("setStateNewValue", {
      key: "hasNoreadMsg",
      value: data.is_red == 1,
    });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
