<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-24 10:32:32
 * @LastEditTime: 2023-06-30 17:52
 * @Descripttion: 
-->
<style lang="scss" scoped>
.navbar {
  width: 95.52%;
  height: 100px;
  margin: 0 auto;
  box-sizing: border-box;
  border-bottom: 1px solid rgba($color: #404040, $alpha: 0.08);
  @include flexBox(flex-end);

  .school-info {
    @include flexBox(center);
    flex-direction: row;
    position: relative;
    z-index: 10;
    margin-right: 55px;
  }

  .notify {
    width: 44px;
    height: 44px;
    background: #ffd25a;
    border-radius: 50%;
    font-size: 28px;
    color: #313131;
    line-height: 44px;
    text-align: center;
    margin: 0 55px 0 108px;
    position: relative;

    &::before {
      content: "";
      width: 12px;
      height: 12px;
      background: #ff0000;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: -2px;
      opacity: 0;
    }

    &::after {
      content: "";
      height: 18px;
      border-right: 1px solid rgba($color: #313131, $alpha: 0.13);
      position: absolute;
      top: 50%;
      right: -29px;
      transform: translateY(-50%);
    }

    &.noread {
      &::before {
        opacity: 1;
      }
    }
  }

  .loginout {
    color: #262626;
    cursor: pointer;
    @include flexBox(flex-end);

    .iconfont {
      display: inline-block;
      width: 44px;
      height: 44px;
      background: #6c4ecb;
      border-radius: 50%;
      font-size: 28px;
      line-height: 44px;
      color: #fff;
      text-align: center;
      margin-right: 12px;
    }
  }

  .breadcrumb {
    margin-right: auto;
    padding-top: 28px;
    font-size: 16px;
    height: 2em;
    color: #484848;
    line-height: 2em;
    @include flexBox;
  }

  .dialog-container {
    width: calc(100% + 72px);
    margin-left: -36px;
    padding: 30px 0 10px;
    position: relative;

    img {
      width: 100%;
    }

    p {
      width: 100%;
      text-align: center;
      color: #afafaf;
      position: absolute;
      line-height: 2em;
      bottom: 24px;
      left: 0;
    }
  }
}
</style>

<template>
  <section class="navbar">
    <template>
      <div class="breadcrumb light" v-if="!isHome">
        <p style="margin-right: 8px">当前位置:</p>
        <el-breadcrumb separator=">">
          <el-breadcrumb-item v-for="(item, index) in breadList" :key="index">
            {{ item.meta.title }}
          </el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <template v-if="isHome">
        <div class="school-info" @click="handleSchool">
          <img src="@assets/images/empty_school.png" alt="" />
          <h5 class="bold">学校简介</h5>
        </div>
      </template>
      <router-link :to="{ name: 'NOTIFY' }" class="notify iconfont" :class="{ 'noread': readMsgStatus }">
        &#xe628;
      </router-link>
      <p class="loginout" @click="loginOut">
        <span class="iconfont">&#xe629;</span>
        <span class="light">退出账号</span>
      </p>
    </template>
    <el-dialog title="学校简介" width="558px" :visible.sync="schoolDialog">
      <div class="dialog-container">
        <img src="@assets/images/no-data.png" alt="" />
        <p>暂未设置学校简介，请前往设置~</p>
      </div>
      <div slot="footer" class="foot-center">
        <el-button type="custom_primary" size="medium" @click="modifySchool">
          去设置
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import { getHomeData } from "@api/home";
import { mapState } from "vuex"
export default {
  name: "Navbar",
  computed: {
    ...mapState('user', ['hasNoreadMsg'])
  },
  data() {
    return {
      isHome: true,
      breadList: [],
      schoolDialog: false,
      hasSchoolDetail: false,
      readMsgStatus: false,
    };
  },
  watch: {
    $route: {
      handler(route) {
        let list = route.matched;
        this.getBreadcrumb(list);
      },
      immediate: true,
    },
    hasNoreadMsg: {
      handler(val) {
        this.readMsgStatus = val;
      },
      immediate: true,
    }
  },
  created() {
    this.getHomeInfo();
  },
  methods: {
    /** 退出登录 */
    loginOut() {
      sessionStorage.clear();
      this.$router.replace({ name: "LOGIN" });
    },
    /** 判断是否是首页 */
    pageHome(route) {
      return route.name == "HOME";
    },
    /** 获取面包屑数据 */
    getBreadcrumb(matched) {
      matched = this.$_.unionBy(matched, "meta.title");
      /* 如果不是首页或者是首页子页面 */
      if (!this.pageHome(matched[0]) || matched.length > 1) {
        matched = [].concat(matched);
        this.isHome = false;
      } else {
        this.isHome = true;
      }
      this.breadList = matched;
    },
    /** 获取首页信息 */
    async getHomeInfo() {
      let { data } = await getHomeData();
      this.hasSchoolDetail = data.details_isedit == 1
    },
    /** 判断学校简介是否存在 */
    handleSchool() {
      if (this.isHome && !this.hasSchoolDetail) {
        this.schoolDialog = true;
      } else {
        this.$router.push({ name: "SCHOOL" });
      }
    },
    /** 编辑学校简介 */
    modifySchool() {
      this.schoolDialog = false;
      setTimeout(() => {
        this.$router.push({ name: "SCHOOL_MODIFY" });
      }, 500);
    },
  },
};
</script>
