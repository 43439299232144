/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-06 14:50:53
 * @Description: 审核管理模块路由
 */
import Layout from "@views/layout"; // 布局

const userRouter = {
  path: "/user",
  component: Layout,
  name: "USER",
  meta: { title: "用户管理", is_menu: true },
  redirect: { name: "AUDIT_COURSEWARE" },
  children: [
    {
      path: "classesGrades",
      name: "USER_CLASSES_GRADES",
      meta: { title: "班级管理", is_menu: true },
      component: () => import("@views/user/classesGrades"),
    },
    {
      path: "student",
      name: "USER_STUDENT",
      meta: { title: "学生管理", is_menu: true },
      component: () => import("@views/user/student"),
    },
    {
      path: "student/detail:id",
      name: "USER_STUDENT_DETAIL",
      meta: {
        title: "学生管理>学生详情",
        is_menu: false,
        prentRoute: "USER_STUDENT",
      },
      component: () => import("@views/user/studentDetail"),
    },
    {
      path: "teacher",
      name: "USER_TEACHER",
      meta: { title: "教师管理", is_menu: true },
      component: () => import("@views/user/teacher"),
    },
    {
      path: "teacher/detail:id",
      name: "USER_TEACHER_DETAIL",
      meta: {
        title: "教师管理>教师详情",
        is_menu: false,
        prentRoute: "USER_TEACHER",
      },
      component: () => import("@views/user/teacherDetail"),
    },
  ],
};

export default userRouter;
