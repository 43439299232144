<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-07-04 09:56
 * @Description: 左侧导航栏
-->
<style lang="scss" scoped>
.sidebar {
  width: 200px;
  height: 100%;
  background: #fff;
  border-radius: 0px 30px 30px 0px;
  box-sizing: border-box;
  padding: 0 0 4vh;
  position: relative;
  z-index: 200;

  .user {
    width: 100%;
    @include flexBox;
    flex-direction: column;
    line-height: 2em;
    height: calc(12.52vh + 60px);
    box-sizing: border-box;
    padding-top: 5.37vh;
    background: #fff;
    position: relative;
    z-index: 2;
    cursor: pointer;

    .avatar {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      position: relative;
      margin-bottom: 10px;

      &::after {
        content: "";
        width: 10px;
        height: 10px;
        background: #1fccb6;
        border-radius: 50%;
        position: absolute;
        bottom: 2px;
        right: 4px;
      }
    }
    .bold{
      width: 100%;
      text-align: center;
    }
  }

  .menu {
    width: 100%;
    height: calc(100% - 12.52vh - 60px);
    overflow: visible;

    i {
      font-family: "iconfont";
      font-style: normal;
      display: inline-block;
    }

    .swiper-wrapper {
      transition-timing-function: linear !important;
    }

    .hide{
      display: none !important;
    }

    &-fir {
      width: 100%;
      height: 40px;
      padding: 1.85vh 0;
      position: relative;

      .title {
        @include flexBox;
        @include defaultAni(0.8s);
        height: 40px;
        box-sizing: border-box;
        padding-left: 38px;
        font-size: 16px;
        color: #3a3a3a;
        cursor: default;
        position: relative;

        i {
          display: inline-block;
          width: 40px;
          height: 40px;
          line-height: 40px;
          text-align: center;
          border-radius: 10px;
          font-size: 24px;
          color: #cbc4e1;
          margin-right: 12px;
        }

        &::after {
          content: "";
          width: 4px;
          height: 32px;
          background: #6c4ecb;
          border-radius: 2px;
          position: absolute;
          top: 50%;
          right: -2px;
          transform: translateY(-50%) scaleY(0);
          @include defaultAni;
        }

        &.link {
          cursor: pointer;
        }
      }

      &.current,
      &:hover {
        .title {
          color: #6c4ecb;

          i {
            background: #6c4ecb;
            color: #fff;
          }

          &:after {
            transform: translateY(-50%) scaleY(1);
            opacity: 1;
          }
        }
      }

      &:hover {
        .menu-sec-box {
          opacity: 1;
          z-index: 3;
          max-height: 100vh;
        }
      }

      &:nth-last-child(1) {
        .menu-sec-box {
          transform: translateY(-85%);

          &::after {
            top: 85%;
          }
        }
      }

      &:nth-last-child(2),
      &:nth-last-child(3) {
        .menu-sec-box {
          transform: translateY(-80%);

          &::after {
            top: 80%;
          }
        }
      }
    }

    &-sec-box {
      max-height: 0;
      overflow: hidden;
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translateY(-50%);
      box-sizing: border-box;
      padding-left: 18px;
      opacity: 0;
      z-index: 0;

      &::after {
        content: "";
        border: solid;
        border-width: 6px 8px 6px 6px;
        border-color: transparent #6c4ecb transparent transparent;
        position: absolute;
        top: 50%;
        left: 4px;
        transform: translateY(-50%);
      }

      p,
      span,
      i {
        white-space: nowrap;
      }

      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        border-radius: 2px;
      }

      ::-webkit-scrollbar-thumb {
        background: #af9bee;
        border-radius: 2px;
      }
    }

    &-sec {
      min-width: 160px;
      min-height: 100px;
      max-height: 500px;
      overflow: hidden;
      overflow-y: auto;
      background: #6c4ecb;
      box-shadow: 0px 0px 18px 0px rgba(94, 36, 239, 0.29);
      border-radius: 10px;
      position: relative;
      box-sizing: border-box;
      padding: 0 24px;

      .sec-item {
        box-sizing: border-box;
        padding: 32px 0;
        border-bottom: 1px solid rgba($color: #e8eaf3, $alpha: 0.13);
        flex-wrap: wrap;
        @include flexBox;
        ;

        &:last-child {
          border-bottom: none;
        }

        &--title {
          height: 2em;
          line-height: 2em;
          font-size: 16px;
          color: #a891e9;

          i {
            font-size: 12px;
          }
        }

        &.current,
        &:not(.current):hover {
          .sec-item--title {
            color: #fff;
          }
        }

        &.current {
          .sec-item--title i {
            transform: rotate(90deg);
          }
        }
      }
    }

    &-third {
      min-width: 100%;
      width: auto;
      max-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding-left: 30px;
      flex-shrink: 0;

      .third-item {
        &--title {
          height: 2.4em;
          line-height: 2.4em;
          font-size: 14px;
          color: #af9bee;
          &:hover{
            cursor: pointer;
          }

          i {
            width: 8px;
            height: 8px;
            position: relative;
            margin-left: 10px;

            &::before {
              content: "";
              width: 10px;
              height: 1px;
              background: #af9bee;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }

            &::after {
              content: "";
              width: 100%;
              height: 1px;
              background: #af9bee;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%) rotate(90deg);
              @include defaultAni(0.3s);
            }
          }
        }

        &.current,
        &:not(.current):hover {
          .third-item--title {
            color: #fff;

            i::after {
              transform: translate(-50%, -50%) rotate(180deg);
            }
          }
        }
      }

      &.show {
        max-height: 100vh;
      }
    }

    &-fourth {
      width: 100%;
      max-height: 0;
      overflow: hidden;
      box-sizing: border-box;
      padding-left: 20px;
      transition-duration: 1s;

      .fourth-item {
        &--title {
          height: 2em;
          line-height: 2em;
          font-size: 14px;
          color: #af9bee;
        }

        &.current,
        &:not(.current):hover {
          .fourth-item--title {
            color: #fff;
          }
        }
      }

      &.show {
        max-height: 100vh;
      }
    }
  }
}
</style>

<template>
  <section class="sidebar" ref="sidebar">
    <div class="user" @click="$router.push({ name: 'PERSON' })">
      <p class="avatar">
        <el-avatar fit="cover" :size="65" :src="formatfile(userInfo.scuse_image)">
          <img src="@assets/images/empty_avatar.png" />
        </el-avatar>
      </p>
      <h5 class="bold line-text--1st">{{ userInfo.scuse_name }}</h5>
    </div>
    <div class="swiper menu" ref="menu">
      <div class="swiper-wrapper">
        <template v-for="(item, index) in menuList">
          <div class="swiper-slide menu-fir" :key="index" @mouseenter="mouseOver(index)"
            v-if="(item.children && !$isEmpty(item.children)) || !item.children"
            :class="{ current: menuFirIndex == index, hide: item.hide }">
            <router-link class="title link" :to="{ name: item.routerName }" v-if="!item.children">
              <i v-html="item.icon" />
              <span>{{ item.title }}</span>
            </router-link>
            <template v-else>
              <p class="title">
                <i v-html="item.icon" />
                <span>{{ item.title }}</span>
              </p>
              <div class="menu-sec-box" @mouseenter="menuSecHover" @mouseleave="menuSecLeave">
                <ul class="menu-sec">
                  <li class="sec-item" v-for="(secitem, secindex) in item.children" :key="secindex"
                    :class="{ current: menuSecIndex == secindex && menuFirIndex == index, hide: secitem.hide }">
                    <router-link class="sec-item--title" :to="{ name: secitem.routerName }" v-if="!secitem.children">
                      {{ secitem.title }}
                    </router-link>
                    <template v-else>
                      <p class="sec-item--title" @click="menuSecIndex = secindex; menuFirIndex = index">
                        <span>{{ secitem.title }}</span>
                        <i class="iconfont">&#xe637;</i>
                      </p>
                      <ul class="menu-third" :class="{ show: menuFirIndex == index && menuSecIndex == secindex }">
                        <li class="third-item"
                          :class="{ current: menuThirdIndex == thirdindex && menuSecIndex == secindex && menuFirIndex == index }"
                          v-for="(thirditem, thirdindex) in secitem.children" :key="thirdindex"
                          @click="menuThirdIndex = thirdindex, thirdTap(thirditem, secitem)">
                          <p class="third-item--title">{{ thirditem.title }}</p>
                        </li>
                      </ul>
                    </template>
                  </li>
                </ul>
              </div>
            </template>
          </div>
        </template>
        <div class="swiper-slide menu-fir">
          <a class="title link"  target="_blank" :href="dgi_value">
            <i class="iconfont">&#xe60b;</i>
            <span>操作文档</span>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import { formatFile } from "@utils"
import { $get_manual } from "@api/common";
import Swiper, { Mousewheel, FreeMode } from "swiper";
import "swiper/swiper.min.css";
Swiper.use([Mousewheel, FreeMode]);

export default {
  name: "Sidebar",
  computed: {
    ...mapState("common", ['gradeClass', 'menuRoot']),
    ...mapState("user", ['userInfo']),
    formatfile() {
      return function (url) {
        return formatFile(url)
      }
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.menuFirIndex = null;
        this.menuSecIndex = null;
        this.menuThirdIndex = null;
        let list = route.matched;
        this.initActiveMenu(list)
      },
      deep: true,
      immediate: true,
    },
    gradeClass: {
      handler(val) {
        if (!this.$isEmpty(val)) {
          this.gradeData = [...val];
          this.initMenu();
        }
      },
      immediate: true,
    },
    menuRoot: {
      handler(val) {
        let menuArr = [];
        val.map(item => {
          menuArr.push(item);
          item.children.map(citem => {
            menuArr.push(citem);
          })
        });
        this.menuRootData = [...menuArr];
        this.initMenu();
      },
      immediate: true,
    },
  },
  data() {
    return {
      gradeData: [], // 班级数据
      menuList: [{
        title: '首页',
        icon: '&#xe609;',
        routerName: 'HOME',
      }, {
        title: '校内课程',
        icon: '&#xe60f;',
        routerName: 'LESSON_LAYOUT',
        children: []
      }, {
        title: '资源中心',
        icon: '&#xe60a;',
        routerName: 'RESOURCES',
        children: [{
          title: '资源库',
          icon: '',
          routerName: 'RESOURCES_COURSEWARE',
        }, {
          title: '试题库',
          icon: '',
          routerName: 'RESOURCES_EXAMINATION',
        }, {
          title: '错题集',
          icon: '',
          routerName: 'RESOURCES_WRONGQUESTIONS',
        }, {
          title: '公开课',
          icon: '',
          routerName: 'RESOURCES_PUBLICCLASS',
        }]
      }, 
      {
        title: '手写板管理',
        icon: '&#xe609;',
        routerName: 'PLABOARD',
      },
      {
        title: '审核管理',
        icon: '&#xe638;',
        routerName: 'AUDIT',
        children: [{
          title: '资源审核',
          icon: '',
          routerName: 'AUDIT_COURSEWARE',
        }, {
          title: '资源共享',
          icon: '',
          routerName: 'AUDIT_COURSEWARE_SHARING',
        }, {
          title: '试卷审核',
          icon: '',
          routerName: 'AUDIT_EXAMINATION',
        }, {
          title: '试卷共享',
          icon: '',
          routerName: 'AUDIT_EXAMINATION_SHARING',
        }, {
          title: '教师审核',
          icon: '',
          routerName: 'AUDIT_TEACHER',
        }]
      }, {
        title: '用户管理',
        icon: '&#xe633;',
        routerName: 'USER',
        children: [{
          title: '班级管理',
          icon: '',
          routerName: 'USER_CLASSES_GRADES',
        }, {
          title: '学生管理',
          icon: '',
          routerName: 'USER_STUDENT',
        }, {
          title: '教师管理',
          icon: '',
          routerName: 'USER_TEACHER',
        }]
      }, {
        title: '数据统计',
        icon: '&#xe610;',
        routerName: 'STATISTICS',
        children: [{
          title: '学情统计',
          icon: '',
          routerName: 'STATISTICS_ACADEMICSENTIMENT',
        }, {
          title: '教师统计',
          icon: '',
          routerName: 'STATISTICS_TEACHER',
        }]
      }, {
        title: '日志中心',
        icon: '&#xe60c;',
        routerName: 'DAILYRECORD'
      }, {
        title: '内容管理',
        icon: '&#xe60b;',
        routerName: 'CONTENT',
        children: [{
          title: '通知公告',
          icon: '',
          routerName: 'CONTENT_NOTICE',
        }, {
          title: '评语管理',
          icon: '',
          routerName: 'CONTENT_REMARK',
        }, {
          title: '文章管理',
          icon: '',
          routerName: 'CONTENT_ARTICLE',
        }, {
          title: '教材管理',
          icon: '',
          routerName: 'CONTENT_TEXTBOOK',
        }]
      }, {
        title: '系统管理',
        icon: '&#xe60e;',
        routerName: 'SYSTEM',
        children: [{
          title: '角色管理',
          icon: '',
          routerName: 'SYSTEM_ROLE',
        }, {
          title: '账号管理',
          icon: '',
          routerName: 'SYSTEM_ACCOUNT'
        },
        {
          title: '课程表时间设置',
          icon: '',
          routerName: 'SYSTEM_TIMETABLE'
        }]
      }],
      menuFirIndex: null,
      menuSecIndex: null,
      menuThirdIndex: null,
      menuSwiper: null,
      index: 0,
      menuRootData: [],
      //操作手册
      dgi_value:"",
    };
  },
  mounted() {
    this.initSwiper();
     /** 获取操作手册 */
    this.get_manual();
  },
  methods: {
    /** 获取操作手册 */
    async get_manual() {
      let { data } = await $get_manual({dgi_code:"SCHOOL"});
      this.dgi_value=data.dgi_value
    },
    /** 导航栏(课程管理)遍历添加年/班级 */
    initMenu() {
      let grade = [];
      let menuList = [...this.menuList];
      this.gradeData.map(gra => {
        let grade_item = {
          title: gra.sccla_name,
          gradeId: gra.sccla_grade,
          icon: '',
          routerName: '',
          children: []
        };
        gra.children.map(cla => {
          let cla_item = {
            title: cla.sccla_name,
            classId: cla.sccla_id,
            icon: '',
            routerName: '',
          }
          grade_item.children.push(cla_item)
        })
        grade.push(grade_item)
      });
      menuList[1].children = [...grade];
      let menuRoot = this.menuRootData.map(item => item.amenu_router_name) || [];
      menuList.map(item => {
        item.hide = false;
        if (menuRoot.indexOf(item.routerName) == -1) {
          item.hide = true;
        } else if (item.children) {
          item.children.map(citem => {
            if (!citem.gradeId) {
              citem.hide = false;
              if (menuRoot.indexOf(citem.routerName) == -1) {
                citem.hide = true;
              }
            }
          })
          let childrenHide = item.children.filter(citem => !citem.gradeId && citem.hide);
          if (childrenHide.length == item.children.length) item.hide = true;
        }
      })
      this.menuList = [...menuList]
    },
    /** 导航栏swiper实例化 */
    initSwiper() {
      this.menuSwiper = new Swiper(".menu", {
        initialSlide: 0,
        direction: "vertical",
        slidesPerView: "auto",
        freeMode: true,
        mousewheel: true,
      });
      setTimeout(() => {
        this.menuSwiper = new Swiper(".menu", {
          initialSlide: 0,
          direction: "vertical",
          slidesPerView: "auto",
          freeMode: true,
          mousewheel: true,
        });
      }, 2000);
    },
    /** 初始化导航栏高亮 */
    initActiveMenu(list) {
      list = this.$_.unionBy(list, "meta.title");
      /* 导航栏遍历 */
      this.menuList.map((item, index) => {
        /* 一级栏目 */
        if (item.title == list[0].meta.title) {
          this.menuFirIndex = index;
          /* 二级栏目遍历 */
          if (item.children) {
            item.children.map((secitem, secindex) => {
              /* 课程管理模块 */
              if (list[1].name == 'LESSON') {
                let className = list[1].meta.title.split('年级');
                /* 课程管理模块的二级栏目 */
                if (secitem.title == `${className[0]}年级`) {
                  this.menuSecIndex = secindex;
                  /* 课程管理模块的三级栏目 */
                  if (secitem.children) {
                    secitem.children.map((thirditem, thirdindex) => {
                      if (thirditem.title == className[1]) {
                        this.menuThirdIndex = thirdindex;
                      }
                    })
                  }
                }
              }
              /* 其他模块 */
              else {
                /* 二级栏目 */
                let title = list[1].meta.title.split('>')[0];
                if (secitem.title == title) {
                  this.menuSecIndex = secindex;
                  /* 三级栏目 */
                  if (secitem.children) {
                    secitem.children.map((thirditem, thirdindex) => {
                      if (thirditem.title == list[2].meta.title) {
                        this.menuThirdIndex = thirdindex;
                      }
                    })
                  }
                }
              }
            })
          }
        }
      })
    },
    /** 一级栏目鼠标移入 */
    mouseOver(index) {
      if (index == 4) {
        this.menuSwiper.slideTo(index, 500, false);
      } else if (index == 3) {
        this.menuSwiper.slideTo(0, 500, false);
      }
    },
    /** 二级栏目鼠标移入 */
    menuSecHover() {
      this.menuSwiper.mousewheel.disable();
    },
    /** 二级栏目鼠标移出 */
    menuSecLeave() {
      this.menuSwiper.mousewheel.enable();
      this.menuFirIndex = null;
      this.menuSecIndex = null;
      this.menuThirdIndex = null;
      this.initActiveMenu(this.$route.matched);
    },
    /** 三级栏目跳转 */
    thirdTap(data, p_data) {
      this.$router.push({
        name: 'LESSON',
        params: { class: `${p_data.title}${data.title}`, id: `${p_data.gradeId},${data.classId}` }
      });
    },
  },
};
</script>
