<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-16
 * @Description: -
-->

<style lang="scss">
@include scrollBar;
</style>

<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>
