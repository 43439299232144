/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-02-01 09:56:55
 * @LastEditTime: 2023-03-14 14:29:29
 * @Descripttion: 
 */
import Vue from "vue";
import "@/assets/styles/element-variables.scss";
import {
  Loading,
  MessageBox,
  Message,
  Notification,
  Button,
  Input,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormItem,
  Table,
  TableColumn,
  Pagination,
  Select,
  Option,
  Icon,
  Switch,
  Progress,
  Timeline,
  TimelineItem,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Upload,
  InputNumber,
  Dialog,
  DatePicker,
  TimePicker,
  Cascader,
  Image,
  Avatar,
  Tag,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  InfiniteScroll,
  Divider,
  Tree,
  Transfer,
  Tooltip,
} from "element-ui";

Vue.use(Button);
Vue.use(Input);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(FormItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option);
Vue.use(Icon);
Vue.use(Switch);
Vue.use(Progress);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Upload);
Vue.use(InputNumber);
Vue.use(Dialog);
Vue.use(DatePicker);
Vue.use(TimePicker);
Vue.use(Cascader);
Vue.use(Image);
Vue.use(Avatar);
Vue.use(Tag);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(InfiniteScroll);
Vue.use(Divider);
Vue.use(Tree);
Vue.use(Transfer);
Vue.use(Tooltip);

Vue.use(Loading.directive);

Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
