<!--
 * @Author: Dyf
 * @Date: 2023-05-17 11:22
 * @LastEditors: Dyf
 * @LastEditTime: 2023-06-26 09:15
 * @Descripttion: 
-->
<template>
  <div id="vue-tinymce">
    <vue-tinymce v-if="showEditor" v-model="editorVlaue" :setting="setting" />
  </div>
</template>

<script>
import { API_ROOT } from "@config";
export default {
  name: "vueTinymceEditor",
  props: {
    // 接收值父组件传递值
    value: {
      type: String,
      default: ''
    },
    // 宽度 默认500
    width: {
      type: Number,
      default: () => {
        return 500;
      },
    },
    // 高度 默认300
    height: {
      type: Number,
      default: () => {
        return 300;
      },
    },
    // 是否开启全功能
    allFunctions: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    //是否显示编辑器 清空编辑器里面的内容使用
    showEditor: {
      type: Boolean,
      default: () => {
        return true;
      }
    },
  },
  watch: {
    value(newValue) {
      this.editorVlaue = newValue
    },
    editorVlaue: function (newValue, oldNum) {
      // 修改调用者传入的值
      this.$emit('input', newValue)
    },
  },
  data() {
    return {
      editorVlaue: this.value,
      setting: {
        menubar: true, // 菜单栏
        //inline: true,   //开启内联模式
        readonly: false, // 只读
        //公式插件
        external_plugins: {},
        toolbar: this.allFunctions
          ? "undo redo | fullscreen | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | image media table emoticons | indent outdent | superscript subscript | removeformat | wordcount code"
          : "undo redo| fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | image media  | wordcount code",
        toolbar_drawer: "sliding",
        //
        quickbars_selection_toolbar:
          "removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor",
        // 配置工具 link连接 image图片 media视频 table表单 lists标签 fullscreen全屏
        plugins: this.allFunctions
          ? "link image media table lists fullscreen quickbars wordcount paste emoticons code autolink autosave imagetools paste preview"
          : "link image media table lists quickbars wordcount paste emoticons code autolink autosave imagetools paste preview ",

        language: "zh_CN",
        height: this.height,
        width: this.width,
        quickbars_insert_toolbar: "image quicktable",
        // 图片上传地址(自动上传)
        // images_upload_url: baseUploadUrl + 'image/vueTinymce/',
        // 允许粘贴图片上传,及格式
        paste_data_images: true,
        images_file_types: "jpg,jpeg,png",
        // 此处为图片上传处理函数 (手动上传)
        images_upload_handler: (blobInfo, success, failure, progress) => {
          // 方法1：用base64的图片形式上传图片
          // const img = 'data:image/jpeg;base64,' + blobInfo.base64()
          // success(img)
          // 方法2：上传oos
          const xhr = new XMLHttpRequest();
          xhr.withCredentials = false;
          xhr.open("POST", API_ROOT + "/protal/tool/upLoadimage");
          // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded;charset=utf-8')
          // xhr.setRequestHeader('x-token', this.$store.getters.token)
          xhr.upload.onprogress = function (e) {
            progress((e.loaded / e.total) * 100);
          };
          // 成功结果
          xhr.onload = function () {
            if (xhr.status != 200) {
              failure("HTTP Error: " + xhr.status, { remove: true });
              return;
            }
            const json = JSON.parse(xhr.responseText);
            if (json.status != 200) {
              failure("图像上传失败:" + json.msg);
              return;
            } else if (json.status == 200) {
              success(json.data.file);
            }
          };
          // 失败结果
          xhr.onerror = function () {
            failure("图像上传失败:" + xhr.status);
          };
          // 请求数据
          const formData = new FormData();
          formData.append("file", blobInfo.blob(), blobInfo.filename());
          xhr.send(formData);
        },
        file_picker_callback: (callback, value, meta) => {
          // failure("123123")
          // return false
          let _this = this;
          //文件分类
          var filetype = ".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
          //后端接收上传文件的地址
          var upurl = API_ROOT + "/protal/tool/uploadFile";
          //为不同插件指定文件类型及后端地址
          switch (meta.filetype) {
            case "image":
              filetype = ".jpg, .jpeg, .png";
              upurl = API_ROOT + "/protal/tool/upLoadimage";
              break;
            case "media":
              filetype = ".mp3,.mp4";
              upurl = API_ROOT + "/protal/tool/uploadFile";
              break;
            case "file":
            default:
          }
          //模拟出一个input用于添加本地文件
          var input = document.createElement("input");
          input.setAttribute("type", "file");
          input.setAttribute("accept", filetype);
          input.click();
          input.onchange = function () {
            var file = this.files[0];
            var xhr, formData;
            xhr = new XMLHttpRequest();
            xhr.withCredentials = false;
            xhr.open("POST", upurl);
            xhr.onload = function () {
              if (xhr.status != 200) {
                alert("文件上传失败：" + xhr.status)
                // failure("文件上传失败：" + xhr.status);
                return;
              }
              const json = JSON.parse(xhr.responseText);
              if (json.status != 200) {
                alert("文件上传失败：" + json.msg)
                // failure("Invalid JSON: " + xhr.responseText);
                return;
              }
              //请求成功
              if (json.status == 200) {
                // 组装扔回去
                callback(json.data.address, { title: file.name });
              }
            };
            formData = new FormData();
            formData.append("file", file, file.name);
            xhr.send(formData);
          };
        },
        setup: function (edt) {
          edt.on("paste", function (e) {
            let items = e.clipboardData && e.clipboardData.items
            console.log(items);
            e.preventDefault();
          });
        }
      },
    };
  },
};
</script>

<style>
/* 富文本按钮功能优先级,最好 > 2500  弹出默认2000+ */
.tox-tinymce-aux {
  z-index: 9999 !important;
}
</style>
