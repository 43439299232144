/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:54:50
 * @LastEditTime: 2023-03-17 16:40:44
 * @Descripttion: 系统管理模块路由
 */
import Layout from "@views/layout"; // 布局

const systemRouter = {
  path: "/system",
  component: Layout,
  name: "SYSTEM",
  meta: { title: "系统管理", is_menu: true },
  redirect: { name: "SYSTEM_ROLE" },
  children: [
    {
      path: "role",
      name: "SYSTEM_ROLE",
      meta: { title: "角色管理", is_menu: true },
      component: () => import("@views/system/role"),
    },
    {
      path: "account",
      name: "SYSTEM_ACCOUNT",
      meta: { title: "账号管理", is_menu: true },
      component: () => import("@views/system/account"),
    },
    {
      path: "timetable",
      name: "SYSTEM_TIMETABLE",
      meta: { title: "课程表时间设置", is_menu: true },
      component: () => import("@views/system/timetable"),
    },
  ],
};

export default systemRouter;
