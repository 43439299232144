/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-06 14:50:53
 * @Description: 资源库模块路由
 */
import Layout from "@views/layout"; // 布局

const resourcesRouter = {
  path: "/resources",
  component: Layout,
  name: "RESOURCES",
  meta: { title: "资源中心", is_menu: true },
  redirect: { name: "RESOURCES_COURSEWARE" },
  children: [
    {
      path: "courseware",
      name: "RESOURCES_COURSEWARE",
      meta: { title: "资源库", is_menu: true },
      component: () => import("@views/resources/courseware"),
    },
    {
      path: "courseware/detail/:id/:type",
      name: "RESOURCES_COURSEWARE_DETAIL",
      meta: {
        title: "资源库>详情",
        is_menu: false,
        prentRoute: "RESOURCES_COURSEWARE",
      },
      component: () => import("@views/coursewareDetail"),
    },
    {
      path: "examination",
      name: "RESOURCES_EXAMINATION",
      meta: { title: "试题库", is_menu: true },
      component: () => import("@views/resources/examination"),
    },
    {
      path: "examination/paper/:id/:type",
      name: "RESOURCES_EXAMINATION_PAPER",
      meta: {
        title: "试题库>试卷详情",
        is_menu: false,
        prentRoute: "RESOURCES_EXAMINATION",
      },
      component: () => import("@views/examinationDetail"),
    },
    {
      path: "examination/paper/append",
      name: "RESOURCES_EXAMINATION_PAPER_APPEND",
      meta: {
        title: "试题库>新增试卷",
        is_menu: false,
        prentRoute: "RESOURCES_EXAMINATION",
      },
      component: () => import("@views/resources/examinationPaperModify"),
    },
    {
      path: "examination/paper/modify:id",
      name: "RESOURCES_EXAMINATION_PAPER_MODIFY",
      meta: {
        title: "试题库>编辑试卷",
        is_menu: false,
        prentRoute: "RESOURCES_EXAMINATION",
      },
      component: () => import("@views/resources/examinationPaperModify"),
    },
    {
      path: "examination/questions/:type/:id",
      name: "RESOURCES_EXAMINATION_QUESTIONS",
      meta: {
        title: "试题库>",
        is_menu: false,
        prentRoute: "RESOURCES_EXAMINATION",
      },
      component: () => import("@views/resources/examinationQuestions"),
      beforeEnter: (to, from, next) => {
        let title = "";
        switch (Number(to.params.type)) {
          case 1:
            title = "试题库>校本题库";
            break;
          case 2:
            title = "试题库>平台题库";
            break;
          default:
            break;
        }
        to.meta.title = title;
        return next();
      },
    },
    {
      path: "wrongQuestions",
      name: "RESOURCES_WRONGQUESTIONS",
      meta: { title: "错题集", is_menu: true },
      component: () => import("@views/resources/wrongQuestions"),
    },
    {
      path: "wrongQuestions/:subject/:id",
      name: "RESOURCES_WRONGQUESTIONS_SUBJECT",
      meta: {
        title: "错题集>",
        is_menu: false,
        prentRoute: "RESOURCES_WRONGQUESTIONS",
      },
      component: () => import("@views/resources/wrongQuestionsSubject"),
      beforeEnter: (to, from, next) => {
        to.meta.title = `错题集>${to.params.subject}错题集`;
        return next();
      },
    },
    {
      path: "publicClass",
      name: "RESOURCES_PUBLICCLASS",
      meta: { title: "公开课", is_menu: true },
      component: () => import("@views/resources/publicClass"),
    },
    {
      path: "publicClass/detail/:id",
      name: "RESOURCES_PUBLICCLASS_DETAIL",
      meta: {
        title: "公开课>详情",
        is_menu: false,
        prentRoute: "RESOURCES_PUBLICCLASS",
      },
      component: () => import("@views/resources/publicClassDetail"),
    },
  ],
};

export default resourcesRouter;
