/*
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-30 09:22:17
 * @Description:
 */
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

/* 自动引入路由器模块 */
const modulesFiles = require.context("./modules", true, /\.js$/);
/* 将自动从模块文件中要求所有路由器模块 */
let modules = [];
modulesFiles.keys().forEach((module) => {
  const modulesConfig = modulesFiles(module);
  if (modulesConfig.default) {
    modules.push(modulesConfig.default);
  }
});

/* 解决vue-router重复点菜单跳转当前页面报错问题 */
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Router.prototype.replace = function replace(location) {
  return originalPush.call(this, location).catch((err) => err);
};

/**
 * 注意:子菜单只在路由 children.length >= 1 时，才出现
 *
 * hidden: true                   如果设置为true，项目将不会显示在侧边栏中（默认为false）
 * alwaysShow: true               如果设置为true，将始终显示根菜单（默认为false）
 *                                如果没有设置alwaysShow，当item有多个子路由时，它将变成嵌套模式，否则不显示根菜单
 * redirect: noRedirect           如果设置noRedirect，则不会在面包屑中重定向
 * name:'router-name'             使用 <keep-alive> （必须设置!），字母全部大写，多个单词以“_”分隔，例：TEST_ONE （推荐全部设置）
 * meta : {
    permission: ['add','edit']   该路由下，按钮的权限，该值一般从接口返回（可以设置多个权限）
    title: 'title'               名称显示在侧边栏和面包屑中（推荐全部设置）
    icon: 'el-icon-x'            图标显示在侧栏中
    keepAlive: true              如果设置为true，页面将会被缓存（默认为false）
    breadcrumb: false            如果设置为false，该项将隐藏在breadcrumb中（默认为true）
    activeMenu: 'router-name'    如果设置路径，侧边栏将突出显示您设置的路径名
  }
 */

/**
 * 路由白名单
 * 没有权限要求的页面
 * 所有角色都可以被访问
 */
export const constantRoutes = [
  {
    path: "/login",
    name: "LOGIN",
    meta: { title: "登录" },
    hidden: true,
    component: () => import("@views/login"),
  },

  {
    path: "/404",
    name: "404",
    meta: { title: "404" },
    hidden: true,
    component: () => import("@views/empty/404"),
  },

  {
    path: "/401",
    name: "401",
    meta: { title: "401" },
    hidden: true,
    component: () => import("@views/empty/401"),
  },
  ...modules,
];

/**
 * 动态路由
 * 需要根据用户角色动态加载的路由
 */
export const asyncRoutes = [
  /**
   * 路由模块
   **/
  // 所有页面都未匹配到时
  { path: "*", hidden: true, redirect: { name: "404" } },
];

const createRouter = () =>
  new Router({
    // mode: 'history', // 需要服务器支持
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // 重置路由器
}

export default router;
