<!--
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-18 21:24:40
 * @Description: 
-->
<style lang="scss" scoped>
.app-main {
  width: 100%;
  height: calc(100vh - 100px);
}
</style>

<template>
  <section class="app-main">
    <!-- 需要缓存的页面 -->
    <keep-alive>
      <router-view :key="key" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <!-- 不用缓存的页面 -->
    <router-view :key="key" v-if="!$route.meta.keepAlive" />
  </section>
</template>

<script>
export default {
  name: "AppMain",
  computed: {
    key() {
      return this.$route.path;
    },
  },
};
</script>
