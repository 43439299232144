/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:54:50
 * @LastEditTime: 2023-03-13 09:54:51
 * @Descripttion: 数据统计模块路由
 */
import Layout from "@views/layout"; // 布局

const statisticsRouter = {
  path: "/statistics",
  component: Layout,
  name: "STATISTICS",
  meta: { title: "数据统计", is_menu: true },
  redirect: { name: "STATISTICS_ACADEMICSENTIMENT" },
  children: [
    {
      path: "academicSentiment",
      name: "STATISTICS_ACADEMICSENTIMENT",
      meta: { title: "学情统计", is_menu: true },
      component: () => import("@views/statistics/academicSentiment"),
    },
    {
      path: "teacher",
      name: "STATISTICS_TEACHER",
      meta: { title: "教师统计", is_menu: true },
      component: () => import("@views/statistics/teacher"),
    },
  ],
};

export default statisticsRouter;
