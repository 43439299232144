/*
 * @Author: Dyf
 * @Date: 2023-02-01
 * @LastEditors: Dyf
 * @LastEditTime: 2023-03-29 14:35:11
 * @Description:
 */
import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import getPageTitle from "@/utils/get-page-title";

const whiteList = ["/login"]; // 不需要重定向的白名单

router.beforeEach(async (to, from, next) => {
  // return
  if (to.name == 404) {
    sessionStorage.removeItem("activeMenu");
  }

  if (to.matched.length == 0) {
    next({ path: "/404" });
  }

  // 设置页面标题
  let title = to.meta.title.split(">");
  document.title = getPageTitle(title[title.length - 1]);

  // 判断用户是否已经登录
  const hasToken = sessionStorage.getItem("token");
  // 判断是否需要修改密码
  const updatePwd = sessionStorage.getItem("updatePwd");
  if (hasToken) {
    //跳转去修改密码
    if(updatePwd&&to.path != "/home/person"){
      Message.warning("为了您的账户安全，请修改密码");
      next({ name: "PERSON" });
    }else if (to.path === "/login") {
      // 如果已登录，则重定向到主页
      next({ name: "HOME" });
    } else if (to.path === "/401" || to.path === "/404") {
      next();
    } else {
      let meunRoot = (from.path === "/login" && []) || store.state.common.menuRoot;
      if (!meunRoot.length) {
        await store.dispatch("common/getMenuRoot");
        meunRoot = store.state.common.menuRoot;
      }
      try {
        let routeArr = [];
        meunRoot.map((item) => {
          routeArr.push(item);
          item.children.map((citem) => {
            routeArr.push(citem);
          });
        });
        routeArr = routeArr.map((item) => item.amenu_router_name) || [];
        let currentRouteName =
          (to.meta.is_menu && to.name) || to.meta.prentRoute;
        if (routeArr.indexOf(currentRouteName) == -1) {
          next({ path: "/401" });
        } else {
          next();
        }
      } catch (error) {
        // 删除令牌并转到登录页面重新登录
        sessionStorage.clear();
        Message.error(error || "有错误");
        next(`/login?redirect=${to.path}`);
      }
    }
  } else {
    /* 没有令牌*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免费登录白名单，直接去
      next();
    } else {
      // 其他没有访问权限的页面被重定向到登录页面。
      next(`/login?redirect=${to.path}`);
    }
  }
});

router.afterEach(() => {});
