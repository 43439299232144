/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Whg
 * @LastEditTime: 2023-05-10 10:31:22
 * @Descripttion: 首页模块接口
 */
import HTTP from "@utils/http";
const PLASCH = "/school/plaschmessage/";
/**
 * 首页数据
 * @param {object} params
 * @returns
 */
export async function getHomeData(params) {
  try {
    return await HTTP.post(PLASCH + "get_home_data", params);
  } catch (error) {
    return error;
  }
}
/**
 * 学校升学
 * @param {object} params
 * @returns
 */
export async function upgradation(params) {
  try {
    return await HTTP.post("/school/schclass/upgradation", params);
  } catch (error) {
    return error;
  }
}
/**
 * 消息列表
 * @param {object} params
 * @returns
 */
export async function getMessageList(params) {
  try {
    return await HTTP.post(PLASCH + "list", params, { load: true });
  } catch (error) {
    return error;
  }
}
/**
 * 消息全部已读
 * @param {object} params
 * @returns
 */
export async function markAllStatus() {
  try {
    return await HTTP.post(PLASCH + "mark_all_status");
  } catch (error) {
    return error;
  }
}
/**
 * 消息标记已读
 * @param {object} params
 * @returns
 */
export async function markStatus(params) {
  try {
    return await HTTP.post(PLASCH + "mark_status", params);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 消息详情
 * @Author: Dyf
 * @param {number} plsch_mes_id 消息id
 * @return {*}
 */
export async function $notifyDetail(plsch_mes_id) {
  try {
    return await HTTP.post(PLASCH + "info", { plsch_mes_id });
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 未读消息状态
 * @Author: Dyf
 * @return {*}
 */
export async function $noReadMsg() {
  try {
    return await HTTP.post(`${PLASCH}no_read_mess_status`);
  } catch (error) {
    return error;
  }
}

/*******
 * @Descripttion: 删除消息通知
 * @Author: Dyf
 * @param {number} plsch_mes_id 消息id
 * @return {*}
 */
export async function $deleteMsg(plsch_mes_id) {
  try {
    return await HTTP.post(`${PLASCH}delete`, { plsch_mes_id });
  } catch (error) {
    return error;
  }
}
