/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-28 21:23:45
 * @Description: 课程模块路由
 */
import Layout from "@views/layout"; // 布局

const lessonRouter = {
  path: "/lesson",
  name: "LESSON_LAYOUT",
  component: Layout,
  meta: { title: "校内课程", is_menu: true },
  redirect: { name: "LESSON" },
  children: [
    {
      path: ":class/:id",
      name: "LESSON",
      meta: { title: "", is_menu: false, prentRoute: "LESSON_LAYOUT" },
      redirect: { name: "LESSON_MANAGE" },
      component: () => import("@views/lesson"),
      beforeEnter: (to, from, next) => {
        to.matched[1].meta.title = to.params.class;
        if (to.matched[2].name == "LESSON_MANAGE")
          to.matched[2].meta.title = to.matched[1].meta.title;
        return next();
      },
      children: [
        {
          path: "calendar",
          name: "LESSON_MANAGE",
          meta: { title: "", is_menu: false, prentRoute: "LESSON_LAYOUT" },
          component: () => import("@views/lesson/calendar"),
        },
        {
          path: "setting",
          name: "LESSON_SET",
          meta: {
            title: "课程表设置",
            is_menu: false,
            prentRoute: "LESSON_LAYOUT",
          },
          component: () => import("@views/lesson/setting"),
        },
        {
          path: "infomation/:day",
          name: "LESSON_INFO",
          meta: {
            title: "课程信息",
            is_menu: false,
            prentRoute: "LESSON_LAYOUT",
          },
          component: () => import("@views/lesson/information"),
        },
        {
          path: "infomation/:day/courseware",
          name: "LESSON_COURSEWARE",
          meta: {
            title: "课程信息>课件详情",
            is_menu: false,
            prentRoute: "LESSON_LAYOUT",
          },
          component: () => import("@views/coursewareDetail"),
        },
      ],
    },
  ],
};

export default lessonRouter;
