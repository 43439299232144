/**
 * @Author: Dyf
 * @Date: 2023-06-25 16:58
 * @LastEditors: Dyf
 * @LastEditTime: 2023-07-06 17:10
 * @Descripttion: 配置文件
 */

/* *********************** 正式环境 ********************** */
// export const API_ROOT = "/api";

/* *********************** 测试环境 ********************** */
export const API_ROOT = "https://api.zytycd.com";

/* *********************** 开发环境 ********************** */
// export const API_ROOT = "/api";
// export const API_ROOT = "/gq_api"; // gq
// export const API_ROOT = "/wyp_api"; // wyp

// 系统标题
export const TITLE = "智源通育材地智能教学生态系统";

// 生产环境和测试环境
// export const DEBUG = process.env.NODE_ENV !== "production";
export const DEBUG = true;

// 设置请求的超时时间   单位毫秒
export const REQUEST_TIMEOUT = 30000;

// 文件服务器地址
// export const FILE_ROOT = "http://192.168.2.123:1026"; // gq
// export const FILE_ROOT = "http://192.168.2.200:1030"; // wyp
export const FILE_ROOT = "https://yucaidi.oss-cn-chengdu.aliyuncs.com/";

export const WORDPASTER_URL = "http://www.ncmem.com:1026/admin/tool/uploadimg";  // 端口号为后端上传服务地址

// 一页显示的条目
export const PAGE_SIZE = 10;