/*
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-13 09:54:37
 * @LastEditTime: 2023-03-15 09:42:01
 * @Descripttion: 内容管理模块路由
 */
import Layout from "@views/layout"; // 布局

const contentRouter = {
  path: "/content",
  component: Layout,
  name: "CONTENT",
  meta: { title: "内容管理", is_menu: true },
  redirect: { name: "SYSTEM_ROLE" },
  children: [
    {
      path: "notice",
      name: "CONTENT_NOTICE",
      meta: { title: "通知公告", is_menu: true },
      component: () => import("@views/content/notice"),
    },
    {
      path: "remark",
      name: "CONTENT_REMARK",
      meta: { title: "评语管理", is_menu: true },
      component: () => import("@views/content/remark"),
    },
    {
      path: "article",
      name: "CONTENT_ARTICLE",
      meta: { title: "文章管理", is_menu: true },
      component: () => import("@views/content/article"),
    },
    {
      path: "article/detail/:id",
      name: "CONTENT_ARTICLE_DETAIL",
      meta: {
        title: "文章管理>文章详情",
        is_menu: false,
        prentRoute: "CONTENT_ARTICLE",
      },
      component: () => import("@views/articleDetail"),
    },
    {
      path: "textbook",
      name: "CONTENT_TEXTBOOK",
      meta: { title: "教材管理", is_menu: true },
      component: () => import("@views/content/textbook"),
    },
    {
      path: "textbook/chapters/:id",
      name: "CONTENT_TEXTBOOK_CHAPTERS",
      meta: {
        title: "教材管理>章节管理",
        is_menu: false,
        prentRoute: "CONTENT_TEXTBOOK",
      },
      component: () => import("@views/content/chapters"),
    },
    {
      path: "textbook/checkChapters/:id",
      name: "CONTENT_TEXTBOOK_CHECKCHAPTERS",
      meta: {
        title: "教材管理>查看章节",
        is_menu: false,
        prentRoute: "CONTENT_TEXTBOOK",
      },
      component: () => import("@views/content/checkChapters"),
    },
    {
      path: "textbook/teateaching/:id",
      name: "CONTENT_TEXTBOOK_TEATEACHING",
      meta: {
        title: "教材管理>备课管理",
        is_menu: false,
        prentRoute: "CONTENT_TEXTBOOK",
      },
      component: () => import("@views/content/teateaching"),
    },
    {
      path: "textbook/prepare/:teteaId",
      name: "CONTENT_TEXTBOOK_PREPARE",
      meta: {
        title: "教材管理>备课管理>添加备课",
        is_menu: false,
        prentRoute: "CONTENT_TEXTBOOK",
      },
      component: () => import("@views/content/prepare"),
    },
  ],
};

export default contentRouter;
