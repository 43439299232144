/*
 * @Author: WHG
 * @Descripttion: 学习管理员
 */
import HTTP from "@utils/http";
const SCHUSER = "/school/schuser/";
/**
 * 修改密码
 * @param {object} params
 * @returns
 */
export async function changePassword(params) {
    try {
      return await HTTP.post(SCHUSER + "change_password",params);
    } catch (error) {
      return error;
    }
  }
  /**
 * 个人信息
 * @param {object} params
 * @returns
 */
export async function getUserInfo(params) {
  try {
    return await HTTP.post(SCHUSER + "get_userinfo",params);
  } catch (error) {
    return error;
  }
}
  /**
 * 上传头像
 * @param {object} params
 * @returns
 */
  export async function userInfoEdit(params) {
    try {
      return await HTTP.post(SCHUSER + "edit",params);
    } catch (error) {
      return error;
    }
  }