/*
 * @Author: Dyf
 * @Date: 2023-02-18 18:25:27
 * @LastEditors: Dyf
 * @LastEditTime: 2023-02-28 20:41:27
 * @Description: 首页模块路由
 */
import Layout from "@views/layout"; // 布局

const homeRouter = {
  path: "/",
  component: Layout,
  name: "HOME",
  meta: { title: "首页", is_menu: true },
  redirect: { name: "HOME_INDEX" },
  children: [
    {
      path: "home",
      name: "HOME_INDEX",
      meta: { title: "首页", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/index"),
    },
    {
      path: "home/person",
      name: "PERSON",
      meta: { title: "个人信息", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/person"),
    },
    {
      path: "home/notify",
      name: "NOTIFY",
      meta: { title: "消息通知", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/notify"),
    },
    {
      path: "home/notify/detail/:id",
      name: "NOTIFY_DETAIL",
      meta: { title: "消息通知>详情", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/notifyDetail"),
    },
    {
      path: "home/school",
      name: "SCHOOL",
      meta: { title: "学校介绍", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/school"),
    },
    {
      path: "home/school/modify",
      name: "SCHOOL_MODIFY",
      meta: { title: "学校介绍>编辑", is_menu: false, prentRoute: "HOME" },
      component: () => import("@views/home/schoolModify"),
    },
  ],
};

export default homeRouter;
